import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';

import CheckIcon from './images/check.inline.svg';

const FormButton = ({ state, theme, className, children }) => {
  const buttonClassNames = classNames(className, {
    'hover: !bg-primary-1': theme === 'primary' && state === 'loading',
    'text-white bg-primary-2 hover:!text-white hover:!bg-primary-2': state === 'success',
  });

  const spinnerClassNames = classNames(
    'w-5 h-5 border-b-4 border-white rounded-full animate-spin',
    {
      'border-white': theme === 'primary',
      'border-black': theme === 'white',
    }
  );

  return (
    <>
      <Button
        className={buttonClassNames}
        type="submit"
        size="md"
        theme={theme}
        disabled={state === 'success' || state === 'loading'}
      >
        {state === 'default' && children}
        {state === 'loading' && (
          <div className="flex items-center justify-center">
            <div className={spinnerClassNames} />
          </div>
        )}
        {state === 'success' && (
          <>
            <div className="w-5 h-5">
              <CheckIcon />
            </div>
          </>
        )}
      </Button>
    </>
  );
};

FormButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  state: PropTypes.oneOf(['default', 'loading', 'success']),
  theme: PropTypes.string.isRequired,
};

FormButton.defaultProps = {
  className: null,
  state: 'default',
};

export default FormButton;
